import {
  type IGenerateUrl,
  type IRequestUrl,
  type ISerialize,
} from '~/interfaces/dto/base/request';
export class BaseService {
  protected readonly baseUrl;
  protected authStore: any;
  constructor(baseUrl?: string) {
    this.baseUrl = baseUrl;
  }

  public serializeQueryString(request: ISerialize): string {
    const str = [];
    const { params } = request;
    if (!params) return '';
    for (const p in params)
      if (params.hasOwnProperty(p)) {
        str.push(
          encodeURIComponent(p) + '=' + encodeURIComponent(String(params[p]))
        );
      }
    return str.join('&');
  }
  public requestUrl({
    path,
    queryParams,
    stitchProtocol = false,
  }: IRequestUrl): string {
    const query = this.serializeQueryString({ params: queryParams });
    const baseUrl = !stitchProtocol
      ? this.baseUrl
      : this.baseUrl.replace('https', 'http');
    return `${baseUrl}/${path}${query ? '/?' + query : ''}`;
  }
  public generateUrl({ baseUrl, path, queryParams }: IGenerateUrl): string {
    const query = this.serializeQueryString({ params: queryParams });
    return `${baseUrl}/${path}/${query ? '?' + query : ''}`;
  }
}
