import { BaseService } from '~/services/base.service';
import { useSiteStore } from '~/stores/site.store';

export const useRegistrationService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.config);
  const siteStore = useSiteStore();

  const fetchTemplate = async () => {
    const requestUrl: string = base.requestUrl({
      path: `cron/registration/jackpotcity/${siteStore?.getRegionCode}`,
    });
    return await $fetch(requestUrl);
  };
  const generateDateOfBirthFromID = (IDNumber: string): IDateOfBirthFromID => {
    //TODO: ADD validation for months < 13 and days < 31
    if (!IDNumber || IDNumber?.length < 6) return;
    const birthDateIdentifier = IDNumber.substring(0, 6);
    const shortYear = parseInt(birthDateIdentifier.substring(0, 2));
    const year =
      shortYear > 21
        ? `19${shortYear}`
        : shortYear < 10 || shortYear === 0
        ? `200${shortYear}`
        : `20${shortYear}`;
    const month = birthDateIdentifier.substring(2, 4);
    const day = birthDateIdentifier.substring(4, 6);
    return {
      shortYear,
      birthDateIdentifier,
      year,
      month,
      day,
    };
  };

  return {
    fetchTemplate,
    generateDateOfBirthFromID,
  };
};
