import { BaseService } from '~/services/base.service';
import type {
  ISitemapRemap,
  ISitemapResponse,
} from '~/interfaces/dto/sitemap/response';
import { useSiteStore } from '~/stores/site.store';
import { remapSitemap } from '~/utilities/sitemap-mapper';
import { useStorageService } from '~/composables/services/useStorageService';

export const useSitemapService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.config);
  const settings = useSiteStore();
  const $storageService = useStorageService();

  const fetchSitemap = async (countryCode: string) => {
    const requestUrl: string = base.requestUrl({
      path: `cron/sitemaps/jackpotcity/${countryCode}`,
      stitchProtocol: Boolean(
        import.meta.server && appConfig.public.enableHttpsBypass
      ),
    });

    const inCache = await $storageService.getIndexDb({
      key: `sitemap-${countryCode}`,
    });
    if (!!inCache) {
      console.info(`Restoring sitemap-${countryCode} from cache`);
      settings.setSiteMap(inCache);
      return inCache;
    }

    console.info(`Fetching fresh sitemap-${countryCode}`);
    return await $fetch<{ siteMaps: ISitemapResponse[] }>(requestUrl, {
      onResponse({ response }) {
        try {
          if (!response._data?.siteMaps) return;
          const sitemap = remapSitemap(response._data.siteMaps[0]?.sitemap);
          settings.setSiteMap(sitemap);
          $storageService.setIndexDb({
            key: `sitemap-${countryCode}`,
            data: sitemap,
          });
        } catch (e) {
          console.error(e);
        }
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
      },
    });
  };

  return { fetchSitemap };
};
