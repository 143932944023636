import { BaseService } from '~/services/base.service';
import { useAuthStore } from '~/stores/auth.store';
import type {
  IDocumentResponse,
  IDocumentUpload,
} from '~/interfaces/dto/compliance/documentVerificationInterfaces';

export const useComplianceService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.authApi);
  const authStore = useAuthStore();

  const getDocuments = async () => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Compliance/uploaded-documents`,
      });

      const { data } = await useFetch(requestUrl, {
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
        onResponse({ request, response, options }) {},
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };

  const uploadDocument = async (document: IDocumentUpload) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Compliance/upload-document`,
      });
      const { data } = await useFetch<IDocumentResponse>(requestUrl, {
        method: 'post',
        body: document,
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
        async onResponse({ request, response, options }) {},
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };

  const deleteDocument = async (docId: string) => {
    try {
      const requestUrl = base.requestUrl({
        path: 'v1/Compliance/delete-document',
      });
      const { data } = await useFetch<any>(requestUrl, {
        method: 'delete',
        query: { documentId: docId },
        headers: { Authorization: `Bearer ${authStore.access_token}` },
        async onResponse({ request, response, options }) {},
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };

  // POST REG

  const getPostRegNames = async () => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Compliance/names-to-verify`,
      });

      const { data } = await useFetch(requestUrl, {
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
        onResponse({ request, response, options }) {
          if (!!response._data && response?._data?.isSuccessful) {
            return response._data.data;
          } else {
            // TODO: Google analytics?
            console.error(response);
          }
        },
        onRequestError({ request, response, options }) {
          console.error(response);
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };

  const verifyPostRegNameSelection = async (selectedName: string) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Compliance/verify-selected-name`,
      });
      const { data } = await useFetch(requestUrl, {
        method: 'post',
        query: { selectedName: selectedName },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
        async onResponse({ request, response, options }) {},
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };

  const sendWelcomeOffer = async (offer: string) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Compliance/EDIT THIS`,
      });
      const { data } = await useFetch(requestUrl, {
        method: 'post',
        query: { welcomeOffer: offer },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
        async onResponse({ request, response, options }) {},
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    getDocuments,
    uploadDocument,
    deleteDocument,
    getPostRegNames,
    verifyPostRegNameSelection,
    sendWelcomeOffer,
  };
};
