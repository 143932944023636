import type {
  IPromotion,
  IPromotionsResponseMap,
} from '~/interfaces/dto/promotions/response';

export const remapPromotions = (
  promotions: IPromotion[]
): IPromotionsResponseMap[] => {
  return promotions?.map(
    ({
      PN: PageName,
      P: Path,
      T: Type,
      SD: StartDate,
      ED: EndDate,
      PGSI,
      isHP: IsHomepage,
      W: Weight,
      C: Caption,
    }) => {
      return {
        PageName,
        Path,
        Type,
        StartDate,
        EndDate,
        PGSI,
        IsHomepage,
        Weight,
        Caption,
      };
    }
  );
};
