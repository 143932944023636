import { useAuthStore } from '~/stores/auth.store';
import { useGameStore } from '~/stores/games.store';
import { useSiteStore } from '~/stores/site.store';

export default async function (): Promise<Array<any>> {
  const { $gameService } = useServices();
  const auth = useAuthStore();
  const games = useGameStore();
  const site = useSiteStore();

  const route = useRoute();
  if (auth.loggedIn) {
    const data = await $gameService.fetchGameFavourites({
      channel: 'WebDesktop',
      languageCode: 'en-US',
      currency: 'USD',
      vertical: site.getCurrentVertical(route)?.Mapping,
      token: auth.access_token,
    });
    games.setFavorites(data);
  }
  return games.returnFavoriteIds;
}
