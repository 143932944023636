import { BaseService } from '~/services/base.service';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';

type EOccurance = 'OnceOff' | 'Daily' | 'Weekly';
type EState = 'Both' | 'LoggedIn' | 'LoggedOut';

export interface IToast {
  id: string;
  name: string;
  includedAccountTags: string | string[];
  excludedAccountTags: string | string[];
  startDateTime: Date;
  endDateTime: Date;
  contentPath: string;
  state: EState;
  occurance: EOccurance;
}

interface IViewedToast {
  id: string;
  name: string;
  includedAccountTags: string | string[];
  excludedAccountTags: string | string[];
  startDateTime: Date;
  endDateTime: Date;
  contentPath: string;
  state: EState;
  occurance: EOccurance;
  viewed: Date;
  nextShowDate: number | boolean | string | Date;
}

export class ToastService extends BaseService {
  private storage;
  constructor(baseUrl, storageService) {
    super(baseUrl);
    this.storage = storageService;
  }

  private toastKey = 'toast';
  private viewedToastsKey = 'toast::viewed';
  private sessionToast = 'toast::session';
  private sessionToastThreshold = 'toast::session::threshold';
  private sessionToastThresholdLimit = 1;

  protected authStore = useAuthStore();
  protected siteStore = useSiteStore();

  private arrayUniqueByKey(array: any[], key: string) {
    return [...new Map(array.map((item) => [item[key], item])).values()];
  }

  async fetchToasts(): Promise<void> {
    try {
      const region = this.siteStore.getRegionCode;
      const requestUrl: string = this.requestUrl({
        path: `api/v1/Toast/${region}`,
      });
      const context = this;
      await $fetch(requestUrl, {
        headers: {
          authorization: this.authStore.isLoggedIn
            ? `Bearer ${this.authStore.access_token}`
            : '',
        },
        onResponse({ request, response }) {
          context.storage.setLocalStorage({
            key: context.toastKey,
            data: response._data,
          });
        },
      });
    } catch (e) {
      console.error(e);
    }
  }
  get toasts() {
    const toasts = this.storage.getLocalStorage({ key: this.toastKey });
    if (!!toasts) {
      const parseToasts = toasts === 'string' ? JSON.parse(toasts) : toasts;
      if (!!parseToasts)
        return parseToasts?.filter((toast) => {
          return this.authStore.isLoggedIn
            ? toast.state === 'LoggedIn' || toast.state === 'Both'
            : toast.state === 'LoggedOut' || toast.state === 'Both';
        });
    } else return [];
  }

  get viewedToasts(): IViewedToast[] {
    const key = this.viewedToastsKey;
    const toasts = this.storage.getLocalStorage({ key });
    if (!!toasts)
      return typeof toasts === 'string' ? JSON.parse(toasts) : toasts;
    else return [];
  }

  get toastViewedInSession() {
    return this.storage.getSessionStorage({ key: this.sessionToast });
  }
  get toastViewedInSessionThreshold() {
    return (
      this.storage.getSessionStorage({
        key: this.sessionToastThreshold,
      }) || 0
    );
  }
  private uniquenessGenerator(name: string, path: string): string {
    return btoa(`${name.replace(/\s/g, '-')}-${path.replace(/\s/g, '-')}`);
  }
  get nextToast() {
    if (!this.viewedToasts.length && this.toasts.length > 1)
      return this.toasts[0];
    if (this.toastViewedInSession) return;
    const contentPaths: string[] = this.viewedToasts.map(({ id }) => id);

    const nextToast = this.toasts.filter(
      ({ id }) => !contentPaths.includes(id)
    )[0];

    if (typeof nextToast === 'undefined' || !nextToast) return;
    return nextToast;
  }

  get nextViewDate() {
    return (occurrence: EOccurance): number | boolean | string | Date => {
      const date = new Date();
      if (occurrence === 'Daily')
        return new Date(date.setDate(date.getDate() + 1));
      if (occurrence === 'Weekly')
        return new Date(date.setDate(date.getDate() + 7));
      if (occurrence === 'OnceOff') return false;
    };
  }

  setToastThresholds(threshold: number) {
    this.storage.setSessionStorage({
      key: this.sessionToastThreshold,
      data: threshold,
    });
  }

  setToastViewed(toast: IToast) {
    const key = this.viewedToastsKey;
    const viewedToasts = this.viewedToasts;
    const hasToastBeenViewed = this.hasToastBeenViewed(toast);
    if (!hasToastBeenViewed) {
      viewedToasts.push({
        ...toast,
        viewed: new Date(),
        nextShowDate: this.nextViewDate(toast.occurance),
      });
    }

    this.storage.setLocalStorage({
      key,
      data: viewedToasts,
    });
    const threshold = this.toastViewedInSessionThreshold;
    if (threshold + 1 <= this.sessionToastThresholdLimit) {
      this.setToastThresholds(threshold + 1);
    }
    if (threshold + 1 === this.sessionToastThresholdLimit) {
      this.storage.setSessionStorage({ key: this.sessionToast, data: true });
    }
  }

  hasToastBeenViewed(toast: IToast): boolean {
    const viewedToasts = this.viewedToasts;
    if (!!viewedToasts && viewedToasts.length) {
      return !!viewedToasts.find(({ id }) => id === toast.id);
    }
    return false;
  }

  generateToast(contentPath: string) {
    const toast = {
      name: 'Test toast',
      includedAccountTags: '',
      excludedAccountTags: '',
      startDateTime: new Date().toISOString(),
      endDateTime: new Date().toISOString(),
      contentPath,
      state: 'LoggedIn',
      occurance: 'OnceOff',
      id: this.uniquenessGenerator('Test toast', contentPath),
      viewed: new Date().toISOString(),
      nextShowDate: false,
    };
    const toasts = this.toasts;
    this.storage.setLocalStorage({
      key: this.toastKey,
      data: [toast, ...toasts],
    });
  }
}
