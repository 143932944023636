import type {
  IAccountBalanceResponse,
  ITransactionDetailResponse,
  ITransactionResponse,
} from '~/interfaces/dto/wallet/response';
import { BaseService } from '~/services/base.service';
import { useAuthStore } from '~/stores/auth.store';
import { useFetch } from '#imports';
import type { ITransactionRequest } from '~/interfaces/dto/wallet/request';
import { useBonusService } from '~/composables/services/useBonusService';

export const useWalletService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.balance);
  const authStore = useAuthStore();
  const retryAuthenticatedRequest = useRetryAuthenticatedRequest;
  // transactions
  const fetchTransactions = async (
    request: ITransactionRequest
  ): Promise<ITransactionResponse> => {
    try {
      const requestUrl = base.requestUrl({ path: 'v1/Wallet/transactions' });
      return await retryAuthenticatedRequest<ITransactionResponse>(requestUrl, {
        method: 'post',
        body: request,
      });
    } catch (e) {
      console.error(e);
    }
  };
  const fetchTransactionDetail = async (
    transactionId: string
  ): Promise<ITransactionDetailResponse> => {
    try {
      const requestUrl = base.requestUrl({
        path: 'v1/Wallet/transaction-details',
      });
      const { data } = await useFetch<ITransactionDetailResponse>(requestUrl, {
        query: { financialReference: transactionId },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };
  const fetchBonusTransactions = async (
    request: ITransactionRequest
  ): Promise<ITransactionResponse> => {
    try {
      const requestUrl = base.requestUrl({
        path: 'v1/Wallet/transactions-bonus',
      });
      return await retryAuthenticatedRequest<ITransactionResponse>(requestUrl, {
        method: 'post',
        body: request,
      });
    } catch (e) {
      console.error(e);
    }
  };
  // balances
  const fetchBalances = async (): Promise<IAccountBalanceResponse> => {
    authStore.setUserbalances({});
    try {
      const requestUrl = base.requestUrl({
        path: 'v1/Wallet/balance',
      });
      if (!authStore.access_token)
        return {
          data: {
            cashBalance: 0,
            bonusBalance: 0,
            ticketBalance: 0,
            playThroughBalance: 0,
            lastBonusCreditTransactionDateTime: new Date(),
          },
          isSuccessful: false,
          responseCode: 401,
          error: 'Access token null',
          metadata: '',
          httpCodeEnum: 401,
        };
      return await $fetch<IAccountBalanceResponse>(requestUrl, {
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
        async onResponse({ request, response, options }) {
          authStore.setUserbalances(response._data.data);
          await useBonusService().getActiveBonus();
        },
        onResponseError() {
          authStore.setUserbalances({
            cashBalance: -1,
            bonusBalance: -1,
            playThroughBalance: -1,
          });
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  return {
    fetchTransactions,
    fetchTransactionDetail,
    fetchBonusTransactions,
    fetchBalances,
  };
};
