import { ToastService } from '~/services/toast.service';
import { useStorageService } from '~/composables/services/useStorageService';

export const useToastService = () => {
  const runtimeConfig = useRuntimeConfig();

  const storageService = useStorageService();

  return {
    toastService: new ToastService(runtimeConfig.public.base, storageService),
  };
};
