import { BaseService } from '~/services/base.service';
import type { IContentResponse } from '~/interfaces/dto/content/response';
import type {
  IPromotion,
  IPromotionsResponse,
  IPromotionsResponseMap,
} from '~/interfaces/dto/promotions/response';
import type { FetchContext, FetchResponse } from 'ofetch';
import { remapPromotions } from '~/utilities/promtions-mapper';
import { parsePromotionContent } from '~/utilities/dom-parser';
import { useStorageService } from './useStorageService';
import { useSiteStore } from '~/stores/site.store';

export const useContentService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.kentico);

  const $storageService = useStorageService();
  const siteStore = useSiteStore();

  const fetchContent = async (
    path: string,
    host?: string
  ): Promise<IContentResponse> => {
    const params = {
      host: host || 'www.jackpotcity.co.za',
      // this needs to be updated
      route: `${path.startsWith('/') ? path : '/' + path}`,
      lang: 'en',
    };

    const inCache = await $storageService.getIndexDb({
      key: `content:${params.route}-${params.host}-${params.lang}`,
    });
    if (!!inCache) return inCache;

    const requestUrl: string = base.requestUrl({
      path: 'gmapi/Content/cmsget',
      queryParams: params,
    });
    return await $fetch(requestUrl, {
      onResponse(context) {
        $storageService.setIndexDb({
          key: `content:${params.route}-${params.host}-${params.lang}`,
          data: context.response._data,
        });
      },
    });
  };

  const fetchPromotion = async (path: string): Promise<string> => {
    const requestUrl: string = base.requestUrl({
      path: path.startsWith('/') ? path.replace('/', '') : path,
      queryParams: {
        culture: 'en-US',
      },
    });
    return await $fetch(requestUrl);
  };

  const fetchBigCityLifeCategory = async (
    requestUrl: string
  ): Promise<string> => {
    return await $fetch(requestUrl);
  };

  const fetchPromotions = async (): Promise<IPromotionsResponseMap[]> => {
    const requestUrl: string = base.requestUrl({
      path: 'gmapi/site/promotionsv2',
      queryParams: {
        host: 'cms1.jpc.africa',
        culture: 'en-US',
        brand: 'jackpotcity',
        brandCode: siteStore.getRegionCode,
      },
    });
    return await $fetch(requestUrl, {
      server: false,
      async onResponse(
        context: FetchContext & {
          response: FetchResponse<IPromotionsResponse>;
        }
      ): Promise<void> {
        const contentMap = context.response._data?.promotionBlocks?.map(
          async (promotion: IPromotion) => {
            const content: string = await fetchPromotion(promotion.P);
            const mappedPromotion = remapPromotions([promotion]);
            return {
              content: parsePromotionContent(content),
              ...mappedPromotion[0],
            };
          }
        );

        context.response._data = await Promise.all(contentMap);
      },
    });
  };

  return {
    fetchContent,
    fetchPromotions,
    fetchPromotion,
    fetchBigCityLifeCategory,
  };
};
