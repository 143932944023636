export function lockBodyScroll() {
  if (!!document && typeof document !== 'undefined') {
    const body = document?.body;
    body.classList.add('overflow-y-hidden');
    body.classList.add('max-h-screen');
  }
}
export function unlockBodyScroll() {
  if (!!document && typeof document !== 'undefined') {
    const body = document?.body;

    body.classList.remove('overflow-y-hidden');
    body.classList.remove('max-h-screen');
  }
}
