import { BaseService } from '~/services/base.service';
import { useAuthStore } from '~/stores/auth.store';
import type {
  ArchivedBonusStack,
  CasinoBonus,
  CasinoBonusStackResponse,
} from '~/interfaces/dto/bonus/response';
import { useFetch } from '#imports';
import type { ITransactionResponse } from '~/interfaces/dto/wallet/response';

export const useBonusService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.player);
  const casinoBonusing = new BaseService(appConfig.public.casinoBonus);
  const authStore = useAuthStore();
  const retryAuthenticatedRequest = useRetryAuthenticatedRequest;
  const tld = 'co.za';

  const getActiveBonus = async (): Promise<CasinoBonus> => {
    if (
      appConfig.public.enableRagingBonusApi.toString() === 'false' ||
      !authStore.user_id
    )
      return;
    try {
      const requestUrl = casinoBonusing.requestUrl({
        path: `${tld}/casinobonusing/api/v1/CasinoBonus/active/${authStore.user_id}`,
      });

      return await retryAuthenticatedRequest<CasinoBonus>(requestUrl, {
        headers: {
          'Cache-Control': 'no-cache',
        },
        onResponse({ response }) {
          authStore.setActiveBonus(response._data);
        },
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const getBonusStack = async (): Promise<CasinoBonusStackResponse> => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/proxy/${tld}/CasinoBonus/player-stack`,
      });
      const { data } = await useFetch<CasinoBonusStackResponse>(requestUrl, {
        headers: {
          'Cache-Control': 'no-cache',
          Authorization: `Bearer ${authStore.access_token}`,
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  const getArchiveBonusStack = async ({
    fromDate,
    toDate,
  }: {
    fromDate: string | Date;
    toDate: string | Date;
  }): Promise<ArchivedBonusStack> => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/proxy/${tld}/CasinoBonus/player-archived-stack`,
      });
      const { data } = await useFetch<ArchivedBonusStack>(requestUrl, {
        headers: {
          'Cache-Control': 'no-cache',
          Authorization: `Bearer ${authStore.access_token}`,
        },
        query: {
          fromDate,
          toDate,
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  const forfeitBonus = async (casinoBonusId: string): Promise<any> => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/proxy/${tld}/CasinoBonus/player-forfeit`,
      });
      const { data } = await useFetch<any>(requestUrl, {
        method: 'post',
        headers: {
          'Cache-Control': 'no-cache',
          Authorization: `Bearer ${authStore.access_token}`,
        },
        body: null,
        query: {
          casinoBonusId,
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  return { getBonusStack, getArchiveBonusStack, forfeitBonus, getActiveBonus };
};
