import { BaseService } from '~/services/base.service';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import type { FetchContext, FetchResponse } from 'ofetch';
import { useStorageService } from '~/composables/services/useStorageService';

export const usePromotionService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.promotions);
  const authStore = useAuthStore();
  const site = useSiteStore();
  const storageService = useStorageService();
  const getBigCityLifeWheelSpinCount = async (): Promise<{
    spinCount: number;
  }> => {
    if (!authStore.access_token) return { spinCount: 0 };
    const requestUrl = base.requestUrl({
      path: `api/v1/BigCityLifeWheel/GetOptinSpinCount`,
    });
    return await $fetch<{
      spinCount: number;
    }>(requestUrl, {
      query: {
        promotionId: 1,
      },
      headers: {
        Authorization: `RR-Bearer ${authStore.access_token}`,
      },
      onResponse(
        context: FetchContext & { response: FetchResponse<ResponseType> }
      ): Promise<void> | void {
        storageService.setLocalStorage({
          key: 'BigCityLifeWheel:SpinCount',
          data: context.response._data.spinCount || 0,
        });
      },
      onResponseError(
        context: FetchContext & { response: FetchResponse<ResponseType> }
      ): Promise<void> | void {
        storageService.setLocalStorage({
          key: 'BigCityLifeWheel:SpinCount',
          data: String(0),
        });
      },
    });
  };

const addOptInTag = async(tagName: string = 'jpcwelcomeoffer') => {
  try{
    const apiBase = new BaseService(appConfig.public.base);
  const requestUrl = apiBase.requestUrl({
    path: `api/v2/Tagging/AddOrEditTagOnVuvuzelaAccount`,
  });
  const request = {
    accountId: authStore.user_id,
    tagName: tagName,
    value: new Date().toISOString(),
  }
  
  const {data} = await useFetch<Boolean>(requestUrl, {
    method: 'post',
    query: {
      regionCode: site.regionCode,
    },
    body: request,
    headers: {
      Authorization: `Bearer ${authStore.access_token}`,
    },
    onResponse(
      {response}
    ){
    },
    onResponseError(
      {response}
    ) {
      console.error(response);
    },
  });
  return data.value;
}
catch(e)
{
  console.error(e)
}
};

  return { getBigCityLifeWheelSpinCount, addOptInTag };
};
