import type { IPromotionContentParse } from '~/interfaces/dto/promotions/response';

const getPromoImage = (documentElement) => {
  const image = (attribute) =>
    documentElement.getElementsByTagName('img')[0]?.getAttribute(attribute);
  return image('src') ? image('src') : image('data-cfsrc');
};
const mapCtaObject = (ctaObject) => {
  if (ctaObject) {
    try {
      return ctaObject ? JSON.parse(ctaObject.trim()) : null;
    } catch (e) {
      return (ctaObject = null);
    }
  }
};
export const parsePromotionContent = (
  content: string
): IPromotionContentParse => {
  const parser = new DOMParser();
  const document = parser.parseFromString(content, 'text/html');
  const promotionImage = getPromoImage(document.getElementById('image'));
  const bigImage = getPromoImage(document.getElementById('bigImage'));
  const promotionHeader = document.getElementById('header').innerHTML;
  const promotionCopy = document.getElementById('copy').innerHTML;
  const moreInfo = document.getElementById('moreInfo').innerHTML;
  const readMoreLink = mapCtaObject(document.getElementById('link').innerHTML);
  const actionLink = mapCtaObject(
    document.getElementById('actionLink').innerHTML
  );
  const registerLink = mapCtaObject(
    document.getElementById('registerLink').innerHTML
  );
  return {
    image: promotionImage,
    bigImage,
    header: promotionHeader,
    text: promotionCopy,
    moreInfo,
    readMoreLink,
    cta: { actionLink, registerLink },
  };
};
