import { BaseService } from '~/services/base.service';
import { useLocaleStore } from '~/stores/locale.store';
import { useSiteStore } from '~/stores/site.store';
import { useStorageService } from '~/composables/services/useStorageService';

export const useLocaleService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.config);
  const locale = useLocaleStore();
  const siteStore = useSiteStore();
  const $storageService = useStorageService();

  const fetchLocale = async (localeCode: string) => {
    if (locale?.localHydrated) return;
    if (!localeCode) {
      try {
        localeCode = siteStore.getConfig?.cultures[0]['cultureCode'];
      } catch (e) {
        localeCode = 'en-US';
      }
    }
    const requestUrl: string = base.requestUrl({
      path: `cron/locales/jackpotcity/${localeCode}`,
      stitchProtocol: Boolean(
        import.meta.server && appConfig.public.enableHttpsBypass
      ),
    });

    const inCache = await $storageService.getIndexDb({
      key: `locale-${localeCode}`,
    });
    if (!!inCache) {
      locale.setLocale(inCache);
      return inCache;
    }

    return await $fetch<Record<string, string>>(requestUrl, {
      onResponse({ response }) {
        locale.setLocale(response?._data);
        $storageService.setIndexDb({
          key: `locale-${localeCode}`,
          data: response?._data,
        });
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
      },
    });
  };
  return { fetchLocale };
};
