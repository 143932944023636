import type { IConfigResponse } from '~/interfaces/dto/config/response';

export const configMapper = (
  config: IConfigResponse
): Partial<IConfigResponse> => {
  const clone = { ...config };
  delete clone.verticals
  delete clone.casinoVerticals
  delete clone.devConfig
  delete clone.properties
  delete clone.applications
  delete clone.channels
  return clone;
};
