import { BaseService } from '~/services/base.service';
import { useSiteStore } from '~/stores/site.store';
import type { IConfigResponse } from '~/interfaces/dto/config/response';
import { configMapper } from '~/utilities/config-mapper';
import { useStorageService } from '~/composables/services/useStorageService';

export const useConfigService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.config);
  const siteStore = useSiteStore();
  const $storageService = useStorageService();

  const fetchConfig = async (countryCode: string) => {
    const requestUrl: string = base.requestUrl({
      path: `cron/appsettings/jackpotcity/${countryCode}`,
      stitchProtocol: Boolean(
        import.meta.server && appConfig.public.enableHttpsBypass
      ),
    });

    const inCache = await $storageService.getIndexDb({
      key: `config-${countryCode}`,
    });
    if (!!inCache) {
      console.info(`Restoring config-${countryCode} from cache`);
      siteStore.setConfig(inCache);
      return inCache;
    }

    console.info(`Fetching fresh config-${countryCode}`);
    return await $fetch<IConfigResponse>(requestUrl, {
      onResponse({ response }) {
        const remappedConfig = configMapper(response._data);
        siteStore.setConfig(remappedConfig);
        $storageService.setIndexDb({
          key: `config-${countryCode}`,
          data: { ...remappedConfig },
        });
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
      },
      onResponseError(context) {
        console.error('[RESPONSE ERROR]:', context);
      },
    });
  };
  const fetchRedirects = async (countryCode: string) => {
    const requestUrl: string = base.requestUrl({
      path: `cron/redirects/jackpotcity/${countryCode}`,
      stitchProtocol: Boolean(
        import.meta.server && appConfig.public.enableHttpsBypass
      ),
    });

    const inCache = await $storageService.getIndexDb({
      key: `redirects-${countryCode}`,
    });
    if (!!inCache) {
      console.info(`Restoring redirects-${countryCode} from cache`);
      siteStore.setRedirects(inCache);
      return inCache;
    }

    console.info(`Fetching fresh redirects-${countryCode}`);
    return await $fetch<{ fromLink: string; toLink: string }[]>(requestUrl, {
      onResponse({ response }) {
        siteStore.setRedirects(response._data);
        $storageService.setIndexDb({
          key: `redirects-${countryCode}`,
          data: response._data,
        });
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
      },
      onResponseError(context) {
        console.error('[RESPONSE ERROR]:', context);
      },
    });
  };

  return { fetchConfig, fetchRedirects };
};
