import { defineStore } from 'pinia';
import { useServices } from '~/composables/useServices';

interface ILocaleData {
  [key: string]: string;
}

interface ILocaleStore {
  debug: boolean;
  messages: ILocaleData; // Replace 'ILocaleData' with the actual interface for your locale data
  locale: string;
  localeOptions: Record<string, string>[];
}

export const useLocaleStore = defineStore('locale', {
  state: (): ILocaleStore => {
    return {
      debug: false,
      messages: {},
      locale: '',
      localeOptions: [{}],
    };
  },
  actions: {
    toggleLocaleDebug() {
      this.debug = !this.debug;
    },
    async changeLocale(locale: string) {
      this.locale = locale;
      const { $storageService } = useServices();
      $storageService.setCookie({ key: 'locale', data: locale, expiry: 365 });
      await this.fetchLocale(locale);
    },
    async fetchLocale(locale: string) {
      this.setLocaleCode(locale);
      const { $localeService } = useServices();
      const data = await $localeService.fetchLocale(locale);
      if (!!data) {
        this.messages = data;
        return data;
      }
    },
    setLocaleCode(locale: string) {
      this.locale = locale;
    },
    setLocale(data: Record<string, string>) {
      this.messages = data;
    },
    async setLocaleOptions(data) {
      const options = [];
      data.forEach((el) => {
        options.push({
          cultureCode: el.cultureCode,
          translation: el.translation,
        });
      });
      this.localeOptions = options;
    },
  },
  getters: {
    getLocale(state) {
      return state.locale || 'en-US';
    },
    debugEnabled(state) {
      return state.debug;
    },
    localeKeyInsensitive(state) {
      return (key: string) => {
        return state?.messages[
          Object.keys(state.messages).find(
            (k) => k?.toLowerCase() === key?.toLowerCase()
          )
        ];
      };
    },
    localeKey(state) {
      return (key: string) => {
        try {
          if (state.debug) {
            console.info({ key, value: state.messages[key] || null });
            return `${key} | ${!state.messages[key] ? 0 : 1}`;
          }
          if (Object.keys(state.messages)?.length === 0) return key;
          return state.messages[key] || this.localeKeyInsensitive(key) || key;
        } catch (e) {
          console.error({ e });
        }
      };
    },
    getLocaleOptions(state) {
      return state.localeOptions;
    },
    localHydrated(state) {
      return Object.keys(state?.messages)?.length;
    },
  },
});
